.add-profile {
  height: 100vh;
  background-color: white;
}

.header {
  height: 8vh;
  background-color: #1890ff;
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  min-height: 50px;
}
.header span {
  font-size: 1.2rem;
  font-weight: 500;
  color: white;
  margin-left: 10px;
}
.form-control {
  margin-bottom: 14px;
}

.ruler {
  height: 1px;
  background-color: #00000020;
}

#table {
  margin-top: 40px;
  width: 100%;
}

.btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0px;
  padding-bottom: 50px;
}

.avatar-uploader {
  background-color: coral;
  height: 90px;
}
