body {
  margin: 0;
  font-family: "Mukta Malar", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pageLoader {
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
}
