.logout-div {
  position: absolute;
  bottom: 20px;
  left: 20px;
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 1px 49px -8px rgba(12, 16, 90, 0.8);
  -webkit-box-shadow: -1px 1px 49px -8px rgba(12, 16, 90, 0.8);
  -moz-box-shadow: -1px 1px 49px -8px rgba(12, 16, 90, 0.8);
  cursor: pointer;
}

.manageprofile-wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: white;
  padding: 10px;
}

.add-btn-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

#logout-btn {
  background: #b73e3e;
  border: none;
}
