.login-main-div {
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
}

.login-form {
  border-radius: 10px;
}
